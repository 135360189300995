import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors";
import Contact from "../templates/contact";

export const query = graphql`
  query ContactQuery {
    allServices: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        slug {
          current
        }
      }
    }
    magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityCategory(sort: { fields: title }) {
      nodes {
        title
        _id
      }
    }
    actions: sanityActions {
      actions {
        action {
          _rawBody
          title
          newPrice
          oldPrice
          icon
          addition
        }
      }
    }
  }
`;

const ContactPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  return <Contact data={data} />;
};

export default ContactPage;
