import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./contactMain.module.css";
import * as commonStyles from "../styles/common.module.css";

function ContactMain() {
  return (
    <div>
      <h1 className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        Kontakt
      </h1>
      <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        {"Beauty Body Clinic"}
      </h2>
      <p className={cn(styles.text, commonStyles.colorDarkGray, commonStyles.fontButler)}>
        {"Kosmická 19"}
      </p>
      <p className={cn(styles.text, commonStyles.colorDarkGray, commonStyles.fontButler)}>
        {"149 00 Praha 4 - Háje"}
      </p>
      <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        {"Otevírací doba"}
      </h2>
      <p className={cn(styles.text, commonStyles.colorDarkGray, commonStyles.fontButler)}>
        {"Pondělí–Pátek: 10–18 hodin"}
      </p>
      <p className={cn(styles.text, commonStyles.colorDarkGray, commonStyles.fontButler)}>
        {"Přijímáme platby v hotovosti i kartou."}
      </p>
      <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        {"Recepce, objednání konzultací"}
      </h2>
      <p className={cn(styles.text, commonStyles.colorDarkGray, commonStyles.fontButler)}>
        {"+420 720 980 530"}
      </p>
      <p className={cn(styles.text, commonStyles.colorDarkGray, commonStyles.fontButler)}>
        {"info@bbclinic.cz"}
      </p>
      <h2 className={cn(styles.title, commonStyles.colorDarkGray, commonStyles.fontButlerMedium)}>
        {"Brand Manager / Spolupráce"}
      </h2>
      <p className={cn(styles.text, commonStyles.colorDarkGray, commonStyles.fontButler)}>
        {"+420 739 510 481"}
      </p>
      <div className={styles.map}>
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              width="800"
              height="400"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=beauty%20body%20clinic&t=&z=15&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMain;
