import React from "react";

import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PageContainer from "../components/pageContainer";
import ContactMain from "../components/contactMain";

const Contact = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const magazinPreview = data && data?.magazinPreview?.nodes;
  const allServices = data && data?.allServices?.nodes;
  const tags = data && data?.tags?.nodes;

  return (
    <Layout services={allServices} tags={tags} magazin={magazinPreview}>
      <SEO
        title={"Kontakt"}
        description={"Kontakt, adresa a užitečné informace."}
        keywords={["Kontakt", "Recepce", "Adresa", "Mapa"]}
      />
      <PageContainer>
        <ContactMain />
      </PageContainer>
    </Layout>
  );
};

export default Contact;
